<template>
  <div class="content">
    <!-- 公共头部 -->
 <Header></Header>

    <Otherban :banner="banner" v-show="bannerStatus == 1"></Otherban>
    <div class="section1">
      <div
        class="item"
        v-for="(item, index) in list"
        @click="navTo(index)"
        :key="index"
      >
        <div class="content">
          <h2 class="title">{{ item.title }}</h2>
          <div class="desc">{{ item.en_title }}</div>
        </div>
        <img class="icon" :src="item.icon" />
      </div>
    </div>
<div class="section3">
<div class="three_item" v-for="(item,index) in newsList" :key="index" >
  <div class="top" v-if="index==0">置顶</div>
  <div class="item_mask" :data-id="item.id" :data-cid="item.cate_id" @click="navToNew"></div>
  <div class="left_pic"><img :src="item.img" alt="" class="pic"></div>
  <div class="right_info">
    <div class="item_title info_item">{{item.title}}</div>
    <div class="item_dec info_item">{{item.description}}</div>
    <div class="item_time info_item">
      <div class="time">{{item.addtime}}</div>
      <div class="icon_box"><img :src="icon1" alt="" class="icon">{{item.num}}</div>
    </div>
  </div>
</div>
</div>
    <div class="section2">
      <!-- <div class="tip">注意事项：请选择报名方式！</div> -->
      <div class="remarks" v-html="remarks"></div>
    </div>

    <div class="mask" v-if="showMask"></div>
    <div class="alert-box" :class="[showMask ? 'active' : '']">
      <h2 class="title">报名条件</h2>
      <div class="singlepage" v-html="singlepage"></div>
      <div class="button_group">
        <div class="btn" @click="showMask = false">取消</div>
        <div class="btn btn1" @click="navToDetail">同意</div>
      </div>
    </div>

    <div class="mask" v-if="config.signup == 0"></div>
    <div class="alert-box1" :class="[config.signup == 0 ? 'active' : '']">
      <img class="icon" src="@/assets/images/icon33.png" />
      <div class="desc">系统维护中，暂停报名。如有问题，请联系客服。</div>
      <div class="btn" @click="$router.back(-1)">返回</div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
import Otherban from "@/components/otherbanner/index.vue";
import icon1 from '@/assets/images/icon15.png';
export default {
  components: {
    Header,
    Footer,
    Otherban,
  },
  data() {
    return {
      title: "报名中心",
      showMask: false,
      bannerStatus: "",
      banner: "",
      icon1:icon1,
      remarks: "",
      newsList:[],
      list: [
        // {
        //   icon: require("../../assets/images/icon20.png"),
        //   title: "活动报名",
        //   en_title: "Individual Registration",
        // },
        {
          icon: require("../../assets/images/icon21.png"),
          title: "碳排放岗位证报名",
          en_title: "Team Registration",
        },
      ],
      singlepage: "",
      curIndex: 0,
      banner: "",
    };
  },
  computed: {
    uid() {
				this.$store.commit('saveUid',this.$route.query.uid)
				return this.$route.query.uid
			},
    config() {
      return this.$store.state.configInfo;
    },
  },
  created() {
    this.getSinglePage();
    this.getbanner();
    this.getRemarks();
    this.getNewsList(9)
  },
  deactivated() {
    this.showMask = false;
  },
  mounted() {
      let wxJs = document.createElement("script");
    wxJs.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
    document.head.appendChild(wxJs);
 
   

  },
  methods: {
    async getSinglePage() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/other/danye", {
        params: { id: 3 },
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "获取报名须知：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.singlepage = res.data.content;
      } else {
        this.singlepage = "";
      }
    },
    async openMask(index) {
      var uid = this.uid
      if (!uid) {
        const confirmResult = await this.$messageBox
          .confirm("请先登录后再进行报名！", "登录提示")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          return this.$toast("已取消操作");
        }
        this.$router.push({
          path: "/login",
          name: "login",
          params: {
            url: this.$route.path,
          },
        });
      } else {
        this.showMask = true;
        this.curIndex = index;
      }
    },
    navTo(e) {
     let index = e;
     let uid = this.uid;
     let flage = 1;
	  if(index==0){
     wx.miniProgram.getEnv(function(res){
      		 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/personalRegist/personalRegist?uid='+uid+'&src=https://tanpei.ks265.net/#/personal_regist'+'&flage='+flage});
               			}else{
						
						}
      				  });
		  //  this.$router.push({path:"/personal_regist",query:{uid:this.uid}});
	  }else if(index==1){
		  this.showMask = true;
		  // this.$router.push("/team_regist");
	  }
      
     
    },
	navToDetail(){
		 this.$router.push("/team_regist");
	},
    // 获取banner图
    async getbanner() {
      this.$toast.loading("加载中");
      const { data: res } = await this.$http.post("/other/advert", { id: 53 });
      console.log(res);
      if (res.code == 1) {
        this.bannerStatus = res.data.status;
        this.banner = "https://tanpei.ks265.net/" + res.data.img;
      }
    },
    // 获取提示信息
    async getRemarks() {
      const { data: res } = await this.$http.get("/other/getSystemValue", {
        params: { name: "baoming_remarks" },
      });
      console.log(res);
      if (res.code == 1) {
        this.remarks = res.data;
      }
    },
    async getNewsList(id) {
				var cateId = id ;
				var page = this.page;
				var limit = this.limit;
					var data = {
					cateId, page, limit
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/article/newsList', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学校动态列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var total = res.data.count;
					this.total = total;
					var newsList = res.data.lists;
					for(var i = 0; i < newsList.length; i++) {
          newsList.sort(function(a,b){
            console.log(b.top);
			return b.top- a.top ;
		})
						newsList[i]['addtime'] = this.utils.js_date_time(newsList[i]['addtime'],1);
						if(newsList[i]['img'] && newsList[i]['img'].substring(0,4) != 'http'){
							newsList[i]['img']=this.$store.state.http + newsList[i]['img']
						}
					}
					this.newsList = newsList;
				} else {
					this.newsList = [];
				}
			},
      navToNew(e){
        let id = e.target.dataset.id;
        let cid = e.target.dataset.cid;
        // this.$router.push({path:'/newsInfo',query:{aid:id,cid:cid}})
          wx.miniProgram.getEnv(function(res){
      		 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/newinfo/newinfo?aid='+id+'&cid='+cid+'&src=https://tanpei.ks265.net/#/newsInfo'});
               			}else{
						
						}
      				  });
      }
  },
};
</script>

<style scoped>
.page_bg {
  padding: 0.3rem;
}
.page_bg .pic {
  width: 100%;
}

.section1 {
  padding:0.3rem;
}
.section1 .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #86cb08;
  border-radius: 0.14rem;
  padding: 0 0.6rem;
  height: 1.8rem;
  margin-top: 0.4rem;
}
.section1 .item:first-child {
  margin-top: 0;
  background: #0372c7;
}
.section1 .item .content {
  display: flex;
  flex-direction: column;
}
.section1 .item .content .title {
  font-size: 0.36rem;
  color: #FFFFFF;
  margin-bottom: 0.14rem;
}
.section1 .item .content .desc {
  font-size: 0.24rem;
  color: #FFFFFF;
}
.section1 .item .icon {
  width: 1.2rem;
  height: 1.2rem;
}

.section2 {
  padding: 0 0.3rem;
  margin-top: 0.6rem;
}
.section2 .tip {
  font-size: 0.28rem;
  color: #ff6000;
  padding-left: 0.55rem;
  background: url(../../assets/images/icon22.png) no-repeat left center;
  background-size: 0.34rem 0.35rem;
  line-height: 0.4rem;
}

.alert-box {
  width: 7rem;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .singlepage {
  font-size: 0.28rem;
  color: #000000;
  line-height: 0.56rem;
  max-height: 5.6rem;
  overflow-y: scroll;
}
.alert-box .button_group {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  background: #d4d4d4;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #0372c7;
}

.alert-box1 {
  width: 4.6rem;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.12rem;
  padding: 0.4rem;
  text-align: center;
  transition: all 0.3s;
}
.alert-box1.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box1 .icon {
  width: 2.41rem;
  height: 1.27rem;
}
.alert-box1 .desc {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.28rem;
  color: #000000;
  line-height: 0.44rem;
  text-align: left;
}
.alert-box1 .btn {
  display: inline-block;
  width: 1.8rem;
  height: 0.7rem;
  line-height: 0.7rem;
  background: #0372c7;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.3rem;
  color: #ffffff;
}
.page_bg {
  height: 3.3rem;
}
.page_bg .bann_wrap {
  height: 100%;
  padding: 0;
}
.page_bg .bann_wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.remarks {
  line-height: 30px; padding-bottom: 1rem;
}
.section3{
  width: 95%;
  margin: 0 auto;
}
.section3 .three_item{
  display: flex;
  justify-content:flex-start;
  align-items: center;

  border-bottom: 1px solid #ccc;
  padding: .1rem;
  position: relative;
}
.section3 .three_item .item_mask{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.section3 .three_item .left_pic{
  width: 40%;
  height: 2rem;
  flex-shrink: 0;
}
.section3 .three_item .left_pic .pic{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.section3 .three_item .right_info{
  text-align: left;
  width: 55%;
 height: 2rem;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
  flex-shrink: 0;
  margin-left: .2rem;
}
.section3 .three_item .right_info .info_item{
  margin-bottom: .2rem;
}
.section3 .three_item .right_info .item_title{
  width: 85%;
  font-size: .31rem;
    overflow: hidden;

text-overflow: ellipsis;

display:-webkit-box; 

-webkit-box-orient:vertical; 

-webkit-line-clamp:1;
}
.section3 .three_item .right_info .item_dec{
  color: #888;
  overflow: hidden;

text-overflow: ellipsis;

display:-webkit-box; 

-webkit-box-orient:vertical; 

-webkit-line-clamp:2;
}
.section3 .three_item .right_info .item_time{
  color: #888;
  font-size: .25rem;
  display: flex;
  justify-content: space-between;
  align-items:flex-end;
  flex:1;
  flex-shrink: 0;
}

.section3 .three_item .right_info .item_time .time{
  margin-right: .2rem;
}
.section3 .three_item .right_info .item_time .icon{
  width: .28rem;
  margin-right: .1rem;
}
.section3 .three_item:nth-child(1){
  border-top: 1px solid #ccc;
}
.section3 .three_item .top{
  font-size: 12px;
 width: .7rem;
 height: .35rem;
 line-height: .35rem;
 text-align: center;
 
  border:1px solid #e0a07a;
  color: #e0a07a;
  position: absolute;
  top: .2rem;
  right: 0px;
}
</style>